<template>
  <div class="main-page">
    <section class="page-section mb-2">
      <div class="container-fluid">
        <div class="grid justify-content-center">
          <div class="col-12 comp-grid">
            <div class="text-muted text-sm text-center mb-2">
                <img
                  class=""
                  src="images/payment_methods.png"
                  alt="logo"
                  style="width: 70%;"
                />
              </div>
            <div class="card mb-0 nice-shadow-6">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startPurchase()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="text-muted text-mg  mb-2 font-bold px-2">
                        Start application form purchase by entering your mobile wallet number
                      </div>
                      <div class="">
                        <InputText
                          ref="ctrltelephone_number"
                          v-model.trim="formData.telephone_number"
                          :label="$t('phone_number')"
                          type="number"
                          :placeholder="$t('phone_number')"
                          pattern="^[0-9]+$"
                          class="w-full"
                          maxlength="10"
                          minlength="10"
                          required="required"
                          v-tooltip.top.focus="'Eg: 0209013836'"
                        >
                        </InputText>
                        
                      </div>
                    </div>
                  </div>
                  <transition
                    appear
                    enter-active-class="animated bounceIn"
                    v-if="errorMsg"
                    leave-active-class="animated fadeOut"
                  >
                    <Message
                      severity="error"
                      :closable="false"
                      @close="errorMsg = ''"
                      >{{ errorMsg.toString() }}
                    </Message>
                  </transition>
                  <div class="text-center mt-2 mb-4">
                    <Button
                      :label="$t('continue')"
                      :loading="saving"
                      class="w-full p-button-primary py-3"
                      type="submit"
                    >
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
export default {
  props: {},
  mixins: [PageMixin],
  data: function () {
    return {
      formData: {
        telephone_number: "",
      },
      saving: false,
      error: "",
      sentMsg: "",
    };
  },
  methods: {
    async startPurchase() {
      this.saving = true;
      this.$api.post("auth/verifyotp", this.formData).then(
        (response) => {
          this.saving = false;
          let resData = response.data;
        if (resData.nextpage) {
            this.$router.push(resData.nextpage);
          } 
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
          this.sentMsg = "";
        }
      );
    },
  },
};
</script>
